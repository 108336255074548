import {
  AboutScreen,
  ChartScreen,
  ChatScreen,
  ContactBirthdayScreen,
  ContactCreateScreen,
  ContactDetailsScreen,
  ContactEditBirthdayScreen,
  ContactEditScreen,
  ContactFilterScreen,
  ContactScreen,
  ContactSearchScreen,
  ContentByCategoryScreen,
  ContentScreen,
  ContractListScreen,
  CreateAccountCreatePasswordScreen,
  CreateActivityScreen,
  DeviceManagementScreen,
  EditActivityScreen,
  EditProfileScreen,
  HomeContentScreen,
  HomeScreen,
  IntegrationProfileScreen,
  LockscreenScreen,
  ManageNotificationsScreen,
  MoreModulesScreen,
  NoteCreateDetailsScreen,
  NoteDetailsScreen,
  NoteEditDetailsScreen,
  NotificationDetailScreen,
  NotificationScreen,
  OpportunityScreen,
  OpportunityDetailsScreen,
  OptionsProfileScreen,
  OrganizationActivityScreen,
  OrganizationOpportunityScreen,
  OrganizationScreen,
  PageErrorOccurredScreen,
  PageNoConnectionScreen,
  PageNotFoundScreen,
  PreferencesProfileScreen,
  ProductionChartScreen,
  ProductionScreen,
  ProfileScreen,
  DashboardScreen,
  RemoveAccountConfirmScreen,
  ResetPasswordEmailSentSuccessfullyScreen,
  ResetPasswordReSentEmailScreen,
  ResetPasswordSendEmailScreen,
  SecurityProfileScreen,
  SimulatorScreen,
  SummaryDetailScreen,
  TabContactScreen,
  TabDetailsScreen,
  TabEditScreen,
  TabFilterScreen,
  TabLocationScreen,
  TabRelatedScreen,
  TabScreen,
  TabSearchScreen,
  TermsScreen,
  WalletScreen,
  DashboardDetailScreen,
  SurveyScreen,
  SurveyDetailScreen,
  LeadsApnefScreen,
  RecommendationsScreen,
  StaffListScreen,
  LeadersListScreen,
  HirePlusScreen,
  ConcludeManagementScreen,
  OpenWalletsPeriodScreen,
  SSOAuthScreen
} from 'src/screens'
import { OpportunityFilterScreen } from 'src/screens/OrganizationScreen/Opportunity/Filters/OpportunityFilterScreen'
import { OpportunityOrderByFilterScreen } from 'src/screens/OrganizationScreen/Opportunity/OrderByFilter'
import OrganizationScreenActivityDesktop from 'src/screens/OrganizationScreen/OrganizationScreenActivity.desktop'
import OrganizationScreenOpportunityDesktop from 'src/screens/OrganizationScreen/OrganizationScreenOpportunity.desktop'
import {
  NoteCreateDetailsContactScreen,
  NoteCreateDetailsTabScreen
} from 'src/screens/Wallet/Notes/NoteCreateDetailsScreen/NoteCreateDetailsScreen'
import {
  NoteEditDetailsContactScreen,
  NoteEditDetailsTabScreen
} from 'src/screens/Wallet/Notes/NoteEditDetailsScreen/NoteEditDetailsScreen'
import WalletContactScreenDesktop from 'src/screens/Wallet/WalletScreenContact.desktop'
import WalletTabScreenDesktop from 'src/screens/Wallet/WalletScreenTab.desktop'

import { RootStackScreenComponent } from '.'
import { TabNavigator } from './TabNavigator'
import { RootStackParamList } from './types'

export const loggedScreens: Partial<{
  [K in keyof RootStackParamList]: RootStackScreenComponent<K>
}> = {
  Tab: TabNavigator,
  Home: HomeScreen,
  MoreModules: MoreModulesScreen,
  Profile: ProfileScreen,
  OptionsProfile: OptionsProfileScreen,
  SecurityProfile: SecurityProfileScreen,
  PreferencesProfile: PreferencesProfileScreen,
  Lockscreen: LockscreenScreen,
  CreateAccountCreatePassword: CreateAccountCreatePasswordScreen,
  DeviceManagement: DeviceManagementScreen,
  IntegrationProfile: IntegrationProfileScreen,
  EditProfile: EditProfileScreen,
  HomeContent: HomeContentScreen,
  Content: ContentScreen,
  ContentByCategory: ContentByCategoryScreen,
  Simulator: SimulatorScreen,
  Opportunity: OpportunityScreen,
  OpportunityDetails: OpportunityDetailsScreen,
  NotificationDetail: NotificationDetailScreen,
  ErrorPageNotFound: PageNotFoundScreen,
  ErrorPageNoConnection: PageNoConnectionScreen,
  ErrorPageErrorOccurred: PageErrorOccurredScreen,
  WalletContact: ContactScreen,
  TabSearch: TabSearchScreen,
  TabFilter: TabFilterScreen,
  WalletTab: TabScreen,
  TabDetails: TabDetailsScreen,
  TabEdit: TabEditScreen,
  TabLocation: TabLocationScreen,
  ContactSearch: ContactSearchScreen,
  ContactFilter: ContactFilterScreen,
  ContactDetails: ContactDetailsScreen,
  ContactBirthday: ContactBirthdayScreen,
  ContactEdit: ContactEditScreen,
  ContactCreate: ContactCreateScreen,
  ContactEditBirthday: ContactEditBirthdayScreen,
  NoteDetails: NoteDetailsScreen,
  ContactEditNoteDetails: NoteEditDetailsContactScreen,
  TabEditNoteDetails: NoteEditDetailsTabScreen,
  EditNoteDetails: NoteEditDetailsScreen,
  TabContact: TabContactScreen,
  TabRelated: TabRelatedScreen,
  //@ts-ignore
  Wallet: WalletScreen,
  Organization: OrganizationScreen,
  OrganizationActivity: OrganizationActivityScreen,
  OrganizationActivityList: OrganizationScreenActivityDesktop,
  OrganizationOpportunity: OrganizationOpportunityScreen,
  OrganizationOpportunityList: OrganizationScreenOpportunityDesktop,
  OpportunityFilter: OpportunityFilterScreen,
  OpportunityOrderByFilter: OpportunityOrderByFilterScreen,
  OrganizationActivityCreate: CreateActivityScreen,
  OrganizationActivityEdit: EditActivityScreen,
  CreateNoteDetails: NoteCreateDetailsScreen,
  ContactCreateNoteDetails: NoteCreateDetailsContactScreen,
  TabCreateNoteDetails: NoteCreateDetailsTabScreen,
  Chart: ChartScreen,
  ProductionChart: ProductionChartScreen,
  Production: ProductionScreen,
  RemoveAccountConfirm: RemoveAccountConfirmScreen,
  ResetPasswordSendEmail: ResetPasswordSendEmailScreen,
  ResetPasswordEmailSentSuccessfully: ResetPasswordEmailSentSuccessfullyScreen,
  ResetPasswordReSentEmail: ResetPasswordReSentEmailScreen,
  About: AboutScreen,
  SummaryDetail: SummaryDetailScreen,
  Terms: TermsScreen,
  Chat: ChatScreen,
  ManageNotifications: ManageNotificationsScreen,
  ContractList: ContractListScreen,
  Notification: NotificationScreen,
  WalletContactList: WalletContactScreenDesktop,
  WalletTabList: WalletTabScreenDesktop,
  Dashboard: DashboardScreen,
  DashboardDetail: DashboardDetailScreen,
  Survey: SurveyScreen,
  SurveyDetail: SurveyDetailScreen,
  LeadsApnef: LeadsApnefScreen,
  Recommendations: RecommendationsScreen,
  StaffList: StaffListScreen,
  LeadersList: LeadersListScreen,
  HirePlus: HirePlusScreen,
  ConcludeManagement: ConcludeManagementScreen,
  OpenWalletsPeriod: OpenWalletsPeriodScreen,
  SSOAuth: SSOAuthScreen
}
