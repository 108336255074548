export type TParams = {
  leader_std_code?: number
  page?: number
  per_page?: number
  order_by?: string
  order_by_direction?: string
  search?: string
  enabled?: boolean
  filter_by?: StoresFilter
}

export type TConcludeParams = {
  leader_std_code: number
  stores?: {
    store_tab_number: number
    description: string
  }[]
}

export type TDetailsParams = {
  leader_std_code: number
  admin_view: boolean
}

export enum StoresFilter {
  AssignedTabs = 0,
  UnassignedTabs = 1
}

export enum WalletManagementStatus {
  Active = 0,
  Closed = 1,
  Concluded = 2
}

export enum WalletManagementGeneralStatus {
  Open = 0,
  Closed = 1
}

export const WalletManagementStatusLabels: { [key in WalletManagementStatus]: string } = {
  [WalletManagementStatus.Active]: 'Ativo',
  [WalletManagementStatus.Closed]: 'Fechado',
  [WalletManagementStatus.Concluded]: 'Concluído'
}

export type TStaffListItem = {
  user_std_code: number
  user_name: string
  occupation: string
  qtd_stores: string
  profile_image_file_name: string
}

export type TLeadersListItem = {
  user_name: string
  occupation: string
  network: string
  user_std_code: string
  subsidiary: string
  qtd_staff: number
  qtd_stores: number
  profile_image_file_name: string
  status: WalletManagementStatus
}

export type TStoresListItem = {
  store_name: string
  store_tab_number: number
  segment: string
  store_image_file_name: string
  specialist?: string
  assigned_by?: string
  assigned_date?: string
}

export type TWalletsManagementDetails = {
  status: WalletManagementStatus
  general_status?: WalletManagementGeneralStatus
  is_reactivated: boolean
  opening_date: string
  closing_date?: string
  last_processed_date?: string
  specialists: {
    total: number
    assigned: number
  }
  tabs: {
    total: number
    assigned: number
  }
  leader_infos?: {
    user_name: string
    occupation: string
    network: string
    user_std_code: string
    subsidiary: string
  }
}

export type TManageWalletParams = {
  leader_std_code: number
  stores_tab_number: string[]
  staff_std_code: string
}

export type TOpenWalletsPeriodParams = {
  date_start: string
  date_end: string
  leader_std_code?: number
}

export type TFinishWalletsPeriodParams = object
