import { useMemo } from 'react'

import { useDisclose } from 'native-base'
import { formatAddressString, formatCEP } from 'src/utils'

import { UseTabInfos } from './TabInfos.types'

export const useTabInfos: UseTabInfos = ({ opportunityDetails, isMobile, isLoading }) => {
  const { isOpen, onToggle } = useDisclose()

  const formattedAddress = useMemo(() => {
    const {
      address,
      address_type,
      address_number,
      address_complement,
      address_neighborhood,
      city_name_ibge,
      address_uf,
      cep_number
    } = opportunityDetails?.store.address ?? {}

    const formattedCEP = formatCEP(cep_number ?? '')

    return `${formatAddressString(
      `${address_type} ${address}, ${address_number}${
        address_complement ? ` - Complemento: ${address_complement}` : ''
      } - ${address_neighborhood}, ${city_name_ibge}`
    )} - ${address_uf}, ${formattedCEP}`
  }, [opportunityDetails?.store.address])

  return {
    opportunityDetails,
    isLoading,
    isMobile,
    isOpen,
    onToggle,
    formattedAddress
  }
}
