import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path, G, Defs, ClipPath } from 'react-native-svg'

export const AttentionIcon: FunctionComponent<IIconProps> = ({ ...props }) => (
  <Icon {...{ viewBox: '0 0 18 18', ...props }}>
    <G fill="currentColor" clipPath="url(#a)">
      <Path
        fillRule="evenodd"
        d="M2.742 2.743a8.85 8.85 0 1 1 12.516 12.515A8.85 8.85 0 0 1 2.742 2.743ZM9 1.65a7.35 7.35 0 1 0 0 14.7 7.35 7.35 0 0 0 0-14.7Z"
        clipRule="evenodd"
      />
      <Path
        fillRule="evenodd"
        d="M9 3.75a.75.75 0 0 1 .75.75v3.6a.75.75 0 1 1-1.5 0V4.5A.75.75 0 0 1 9 3.75Z"
        clipRule="evenodd"
      />
      <Path d="M9 12.818a.675.675 0 1 1 0-1.35" />
      <Path
        fillRule="evenodd"
        d="M9 12.218a.075.075 0 1 0 0-.15v-1.35a1.425 1.425 0 1 0 0 2.85v-1.35Z"
        clipRule="evenodd"
      />
      <Path d="M9 12.818a.675.675 0 1 0 0-1.35" />
      <Path
        fillRule="evenodd"
        d="M9 10.718a1.425 1.425 0 1 1 0 2.85v-1.35a.075.075 0 1 1 0-.15v-1.35Z"
        clipRule="evenodd"
      />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="currentColor" d="M0 0h18v18H0z" />
      </ClipPath>
    </Defs>
  </Icon>
)
