import { AxiosError, AxiosResponse } from 'axios'
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query'

import * as requests from './requests'
import {
  TStaffListItem,
  TParams,
  TStoresListItem,
  TWalletsManagementDetails,
  TLeadersListItem,
  TConcludeParams,
  TManageWalletParams,
  TOpenWalletsPeriodParams,
  TFinishWalletsPeriodParams
} from './types'
import { ResponseError, ResponseSuccess } from '../../types'

export const KEY_STAFF_LIST_QUERY = 'getStaffList'

export const KEY_LEADERS_LIST_QUERY = 'getLeadersList'

export const KEY_STORES_LIST_QUERY = 'getStoresList'

export const KEY_WALLETS_MANAGEMENT_DETAILS_QUERY = 'getWalletsManagementDetails'

export const useGetStaffListInfinityQuery = ({ enabled = true, ...params }: TParams) =>
  useInfiniteQuery<AxiosResponse<ResponseSuccess<TStaffListItem[]>>, AxiosError<ResponseError>>(
    [KEY_STAFF_LIST_QUERY, params],
    // @ts-ignore
    ({ pageParam }) => requests.getStaffList({ ...params, currentPage: pageParam }),
    {
      enabled,
      getNextPageParam: (lastPage) => {
        if (
          lastPage.data.metadata.pagination.current_page <
          lastPage.data.metadata.pagination.last_page
        ) {
          return lastPage.data.metadata.pagination.current_page + 1
        }

        if (
          lastPage.data.metadata.pagination.current_page ===
          lastPage.data.metadata.pagination.last_page
        ) {
          return false
        }
      }
    }
  )

export const useGetLeadersListInfinityQuery = ({ enabled = true, ...params }: TParams) =>
  useInfiniteQuery<AxiosResponse<ResponseSuccess<TLeadersListItem[]>>, AxiosError<ResponseError>>(
    [KEY_LEADERS_LIST_QUERY, params],
    // @ts-ignore
    ({ pageParam }) => requests.getLeadersList({ ...params, currentPage: pageParam }),
    {
      enabled,
      getNextPageParam: (lastPage) => {
        if (
          lastPage.data.metadata.pagination.current_page <
          lastPage.data.metadata.pagination.last_page
        ) {
          return lastPage.data.metadata.pagination.current_page + 1
        }

        if (
          lastPage.data.metadata.pagination.current_page ===
          lastPage.data.metadata.pagination.last_page
        ) {
          return false
        }
      }
    }
  )

export const useGetStoresListInfinityQuery = ({ enabled = true, ...params }: TParams) =>
  useInfiniteQuery<AxiosResponse<ResponseSuccess<TStoresListItem[]>>, AxiosError<ResponseError>>(
    [KEY_STORES_LIST_QUERY, params],
    // @ts-ignore
    ({ pageParam }) => requests.getStoresList({ ...params, currentPage: pageParam }),
    {
      enabled,
      getNextPageParam: (lastPage) => {
        if (
          lastPage.data.metadata.pagination.current_page <
          lastPage.data.metadata.pagination.last_page
        ) {
          return lastPage.data.metadata.pagination.current_page + 1
        }

        if (
          lastPage.data.metadata.pagination.current_page ===
          lastPage.data.metadata.pagination.last_page
        ) {
          return false
        }
      }
    }
  )

export const useGetWalletsManagementDetailsQuery = ({
  leader_std_code,
  admin_view
}: {
  leader_std_code: number
  admin_view: boolean
}) => {
  return useQuery<
    AxiosResponse<ResponseSuccess<TWalletsManagementDetails>>,
    AxiosError<ResponseError>
  >([KEY_WALLETS_MANAGEMENT_DETAILS_QUERY, leader_std_code], () =>
    requests.getWalletsManagementDetails({ admin_view, leader_std_code })
  )
}

export const useConcludeWalletsManagementMutation = () =>
  useMutation<
    AxiosResponse<ResponseSuccess<TConcludeParams>>,
    AxiosError<ResponseError>,
    TConcludeParams
  >(requests.concludeWalletsManagement)

export const useManageWalletMutation = () =>
  useMutation<
    AxiosResponse<ResponseSuccess<TManageWalletParams>>,
    AxiosError<ResponseError>,
    TManageWalletParams
  >(requests.manageWallet)

export const useOpenWalletsPeriodMutation = () => {
  const queryClient = useQueryClient()

  return useMutation<
    AxiosResponse<ResponseSuccess<TOpenWalletsPeriodParams>>,
    AxiosError<ResponseError>,
    TOpenWalletsPeriodParams
  >(requests.openWalletsPeriod, {
    onSuccess: () => {
      queryClient.invalidateQueries([KEY_WALLETS_MANAGEMENT_DETAILS_QUERY])
    }
  })
}

export const useFinishWalletsPeriodMutation = () => {
  const queryClient = useQueryClient()

  return useMutation<
    AxiosResponse<ResponseSuccess<TFinishWalletsPeriodParams>>,
    AxiosError<ResponseError>,
    TFinishWalletsPeriodParams
  >(requests.finishWalletsPeriod, {
    onSuccess: () => {
      queryClient.invalidateQueries([KEY_WALLETS_MANAGEMENT_DETAILS_QUERY])
    }
  })
}
