import { useEffect, useMemo } from 'react'

import { useGetHirePlusUrlQuery } from 'integration/resources/hirePlus'
import { useBreakpointValue } from 'native-base'

import { UseHirePlusScreen } from './HirePlusScreen.types'

export const useHirePlusScreen: UseHirePlusScreen = ({ navigation, route }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const { data: hirePlusData, isLoading } = useGetHirePlusUrlQuery()

  const url = hirePlusData?.data?.data?.url

  const session_token = hirePlusData?.data?.data?.session_token

  const iFrameUrl = useMemo(
    () => (url && session_token ? `${url}?session_token=${session_token}` : ''),
    [url, session_token]
  )

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      console.log('Received message:', event)

      console.log('Message origin:', event.origin)

      console.log('iFrameUrl:', iFrameUrl)

      const iframeOrigin = new URL(iFrameUrl).origin

      console.log('iframeOrigin:', iframeOrigin)

      if (event.origin !== iframeOrigin) {
        console.warn('Message received from unexpected origin:', event.origin)

        return
      }

      const iframe = document.getElementById('hireplus-iframe') as HTMLIFrameElement

      if (!iframe) {
        console.error('Iframe element not found')

        return
      }

      console.log('Message data:', event.data)

      if (event?.data?.action === 'expandirIframe') {
        console.log('Expanding iframe')

        iframe.style.position = 'fixed'

        iframe.style.top = '0'

        iframe.style.left = '0'

        iframe.style.width = '100vw'

        iframe.style.height = '100vh'

        iframe.style.border = 'none'

        iframe.style.zIndex = '9999'

        console.log('Iframe expanded successfully')
      } else if (event?.data?.action === 'reduzirIframe') {
        console.log('Reducing iframe')

        iframe.style.position = 'static'

        iframe.style.width = '100%'

        iframe.style.height = '90%'

        iframe.style.zIndex = 'auto'

        console.log('Iframe reduced successfully')
      } else {
        console.warn('Unrecognized action:', event?.data?.action)
      }
    }

    window.addEventListener('message', handleMessage)

    console.log('Message event listener added')

    return () => {
      window.removeEventListener('message', handleMessage)

      console.log('Message event listener removed')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    isMobile,
    iFrameUrl,
    isLoading
  }
}
