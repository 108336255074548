/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useMemo, useState } from 'react'

import { useBreakpointValue } from 'native-base'
import { Dimensions, Platform } from 'react-native'
import { onlineManager } from 'react-query'

import { UseBlocksArcProgress } from './BlocksArcProgress.types'

const isOnline = onlineManager.isOnline()

const calculatePercent = (base_value: number, realized_value: number, max_value: number) => {
  if (realized_value > 0) {
    if (realized_value < base_value) {
      const progress = (realized_value / base_value) * 100

      return progress / 2
    } else {
      const realized_value_real = realized_value - base_value

      const max_value_real = max_value - base_value

      const progress = (realized_value_real * 100) / max_value_real

      return 50 + progress / 2
    }
  }

  return 0
}

const widthScreen = () => {
  const width = Dimensions.get('window').width

  if (width < 360) return 'small'

  if (width >= 360 && width <= 400) return 'medium'

  return 'big'
}

const isIos = Platform.OS === 'ios'

const isDesktop = Platform.OS === 'web' && Dimensions.get('window').width > 600

const configs = {
  small: {
    arc: {
      size: 230,
      width: 12
    },
    points: {
      '0': {
        bottom: isDesktop ? '30%' : '27%',
        left: isDesktop ? '4%' : '14%',
        text: {
          fontSize: 'xs'
        }
      },
      '100': {
        top: '0%',
        bar: {
          top: '10%',
          height: '12px'
        },
        text: {
          fontSize: 'xs',
          top: '2%'
        }
      },
      '110': {
        bar: {
          height: '12px',
          top: '12.6%',
          right: '37%',
          rotate: '23deg'
        },
        text: {
          fontSize: 'xs',
          top: '7%',
          right: '23%'
        }
      },
      '120': {
        bar: {
          height: '12px',
          top: '20.5%',
          right: '26%',
          rotate: '43deg'
        },
        text: {
          fontSize: 'xs',
          top: '14%',
          right: '12.5%'
        }
      },
      '130': {
        bar: {
          height: '12px',
          top: '32%',
          right: '18.6%',
          rotate: '64deg'
        },
        text: {
          fontSize: '12px',
          top: '27%',
          right: '4.5%'
        }
      },
      '140': {
        bar: {
          height: '12px',
          top: '46.2%',
          right: '15.5%',
          rotate: '80deg'
        },
        text: {
          fontSize: 'xs',
          top: '43%',
          right: '0.9%'
        }
      },
      '150': {
        text: {
          fontSize: 'xs'
        },
        bottom: isDesktop ? '30%' : '27%',
        right: isDesktop ? '7%' : '14%'
      }
    },
    realized: {
      top: '25%',
      fontSize: 'xs',
      value: {
        fontSize: '30px'
      },
      points: {
        fontSize: '10px'
      }
    },
    projected: {
      bottom: '5%',
      left: '16%',
      fontSize: 'xs',
      value: {
        fontSize: 'xs'
      },
      points: {
        fontSize: '10px'
      }
    },
    simulated: {
      bottom: '5%',
      right: '16%',
      fontSize: 'xs',
      value: {
        fontSize: 'xs'
      },
      points: {
        fontSize: '10px'
      }
    }
  },
  medium: {
    arc: {
      size: isIos ? 260 : 270,
      width: 15
    },
    points: {
      '0': {
        bottom: isDesktop ? '30%' : '24.5%',
        left: isDesktop ? '-3%' : '7%',
        text: {
          fontSize: 'xs'
        }
      },
      '100': {
        top: '6%',
        bar: {
          top: '10%',
          height: '15px'
        },
        text: {
          fontSize: 'xs',
          top: isIos ? '2%' : '3%'
        }
      },
      '110': {
        bar: {
          height: '15px',
          top: isIos ? '12.9%' : '12.8%',
          right: '34.6%',
          rotate: '23deg'
        },
        text: {
          fontSize: 'xs',
          top: isIos ? '7.2%' : '7.5%',
          right: isIos ? '20.2%' : '21%'
        }
      },
      '120': {
        bar: {
          height: '15px',
          top: isIos ? '21%' : '20.4%',
          right: '22.6%',
          rotate: '45deg'
        },
        text: {
          fontSize: 'xs',
          top: isIos ? '16%' : '15.5%',
          right: isIos ? '8%' : '9%'
        }
      },
      '130': {
        bar: {
          height: '15px',
          top: '32.8%',
          right: isIos ? '14.5%' : '13.5%',
          rotate: '67deg'
        },
        text: {
          fontSize: 'xs',
          top: '31%',
          right: isIos ? '-1%' : '-2%'
        }
      },
      '140': {
        bar: {
          height: '14px',
          top: '47%',
          right: isIos ? '11.2%' : '9.8%',
          rotate: '85deg'
        },
        text: {
          fontSize: 'xs',
          top: '45%',
          right: '-5.5%'
        }
      },
      '150': {
        text: {
          fontSize: 'xs'
        },
        bottom: isDesktop ? '30%' : '24.5%',
        right: isIos ? '1%' : '2%'
      }
    },
    realized: {
      top: '25%',
      fontSize: 'md',
      value: {
        fontSize: '36px'
      },
      points: {
        fontSize: isDesktop ? '16px' : '10px'
      }
    },
    projected: {
      bottom: isDesktop ? '-1.5%' : '5.5%',
      left: '19%',
      fontSize: 'xs',
      value: {
        fontSize: '14px'
      },
      points: {
        fontSize: '10px'
      }
    },
    simulated: {
      bottom: isDesktop ? '-1.5%' : '5.5%',
      right: '18%',
      fontSize: 'xs',
      value: {
        fontSize: '14px'
      },
      points: {
        fontSize: '10px'
      }
    }
  },
  big: {
    arc: {
      size: isDesktop ? 335 : 280,
      width: isDesktop ? 23 : 15
    },
    points: {
      '0': {
        bottom: isDesktop ? '10%' : '22%',
        left: isDesktop ? '5%' : '-6%',
        text: {
          fontSize: 'sm'
        }
      },
      '100': {
        top: '6%',
        bar: {
          top: '10%',
          height: isDesktop ? '23px' : '15px'
        },
        text: {
          fontSize: 'xs',
          top: '2%'
        }
      },
      '110': {
        bar: {
          height: isDesktop ? '21px' : '15px',
          top: isDesktop ? '14.3%' : '12.8%',
          right: isDesktop ? '30.2%' : '34.6%',
          rotate: isDesktop ? '24deg' : '23deg'
        },
        text: {
          fontSize: 'xs',
          top: '7.2%',
          right: '20.2%'
        }
      },
      '120': {
        bar: {
          height: isDesktop ? '22px' : '14px',
          top: isDesktop ? '24.4%' : '21.9%',
          right: isDesktop ? '15%' : '20.7%',
          rotate: '45deg'
        },
        text: {
          fontSize: 'xs',
          top: isDesktop ? '18%' : '16%',
          right: isDesktop ? '2%' : '7%'
        }
      },
      '130': {
        bar: {
          height: isDesktop ? '22px' : '14px',
          top: isDesktop ? '39.4%' : '35%',
          right: isDesktop ? '4.7%' : '11.8%',
          rotate: isDesktop ? '65deg' : '67deg'
        },
        text: {
          fontSize: 'xs',
          top: isDesktop ? '36.4%' : '31.4%',
          right: isDesktop ? '-11%' : '-3%'
        }
      },
      '140': {
        bar: {
          height: isDesktop ? '22px' : '14px',
          top: isDesktop ? '57%' : '50%',
          right: isDesktop ? '0.6%' : '8.1%',
          rotate: isDesktop ? '84deg' : '81deg'
        },
        text: {
          fontSize: 'xs',
          top: isDesktop ? '56%' : '47%',
          right: isDesktop ? '-16%' : '-7%'
        }
      },
      '150': {
        text: {
          fontSize: 'sm'
        },
        bottom: isDesktop ? '10%' : '22%',
        right: isDesktop ? '-5%' : '-8%'
      }
    },
    realized: {
      top: isDesktop ? '42%' : '27%',
      fontSize: 'md',
      value: {
        fontSize: '36px'
      },
      points: {
        fontSize: '10px'
      }
    },
    projected: {
      bottom: '5%',
      left: '12%',
      fontSize: 'xs',
      value: {
        fontSize: '14px'
      },
      points: {
        fontSize: '10px'
      }
    },
    simulated: {
      bottom: '5%',
      right: '12%',
      fontSize: 'xs',
      value: {
        fontSize: '14px'
      },
      points: {
        fontSize: '10px'
      }
    }
  }
}

const positions = configs[widthScreen()]

const heightBox = widthScreen() === 'small' ? 72 : 80

export const useBlocksArcProgress: UseBlocksArcProgress = ({
  progress_values,
  projected,
  realized,
  simulated,
  accelerators,
  performance,
  isLoading
}) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const verifyData = (data: string | number) => isOnline && !isLoading && data

  const hasProgress = useMemo(() => progress_values?.base, [progress_values?.base])

  const [progressRealized, setProgressRealized] = useState(0)

  const [progressProjected, setProgressProjected] = useState(0)

  const [progressSimulated, setProgressSimulated] = useState(0)

  useEffect(() => {
    if (realized?.value !== undefined) {
      const sanitizedProgressRealized = calculatePercent(
        progress_values.base,
        Number(realized?.value),
        progress_values.max
      )

      setProgressRealized(sanitizedProgressRealized)
    }
  }, [realized])

  useEffect(() => {
    if (projected?.value !== undefined) {
      const sanitizedProgressProjected = calculatePercent(
        progress_values.base,
        Number(projected?.value),
        progress_values.max
      )

      setProgressProjected(sanitizedProgressProjected)
    }
  }, [projected])

  useEffect(() => {
    if (simulated?.value !== undefined) {
      const sanitizedProgressSimulated = calculatePercent(
        progress_values.base,
        Number(simulated?.value),
        progress_values.max
      )

      setProgressSimulated(sanitizedProgressSimulated)
    }
  }, [simulated])

  const redSituation = progressRealized < 100

  return {
    isMobile,
    isOnline,
    isIos,

    arcProgressData: { progress_values, projected, realized, simulated, accelerators, performance },

    hasProgress,
    progressRealized,
    progressProjected,
    progressSimulated,
    redSituation,

    positions,
    heightBox,
    verifyData
  }
}
