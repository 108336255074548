import { format } from 'date-fns'

import {
  TStaffListItem,
  TParams,
  TStoresListItem,
  TWalletsManagementDetails,
  TLeadersListItem,
  TConcludeParams,
  WalletManagementStatus,
  TDetailsParams,
  WalletManagementGeneralStatus,
  TManageWalletParams,
  TOpenWalletsPeriodParams,
  TFinishWalletsPeriodParams
} from './types'
import client from '../../client'
import { ResponseSuccess } from '../../types'

const USE_MOCK = true

const getDynamicStatus = (index: number): WalletManagementStatus => {
  return (index % 3) as WalletManagementStatus
}

const mockAxiosResponse = (data: any) => ({
  data,
  status: 200,
  statusText: 'OK',
  headers: {},
  config: {},
  request: {}
})

export const mockedStoresListResponse = {
  serviceSuccess: true,
  hasErrors: false,
  errors: [],
  metadata: {
    pagination: {
      total: 10,
      per_page: 10,
      current_page: 1,
      last_page: 1,
      first_page: 1,
      first_page_url: '/?page=1',
      last_page_url: '/?page=1',
      next_page_url: null,
      previous_page_url: null
    },
    runtime: 35,
    requestId: 'clujqrna8000908jtbk9d45ub'
  },
  data: Array.from({ length: 10 }, (_, index) => ({
    store_name: `Grupo Fiat ${index + 1}`,
    store_tab_number: `654${100 + index}`,
    segment: `Motos (VarejoG)`,
    store_image_file_name: `https://randomuser.me/api/portraits/men/${index}.jpg`,
    assigned_by: index < 3 ? null : `Líder ${index + 1}`,
    assigned_date: index < 3 ? null : `12/09/2024`,
    specialist: index < 3 ? null : `Funcionário ${index + 1}`
  })),
  status: 200
}

export const mockedStaffListResponse = {
  serviceSuccess: true,
  hasErrors: false,
  errors: [],
  metadata: {
    pagination: {
      total: 10,
      per_page: 10,
      current_page: 1,
      last_page: 1,
      first_page: 1,
      first_page_url: '/?page=1',
      last_page_url: '/?page=1',
      next_page_url: null,
      previous_page_url: null
    },
    runtime: 35,
    requestId: 'clujqrna8000908jtbk9d45ub'
  },
  data: Array.from({ length: 10 }, (_, index) => ({
    user_name: `Funcionário ${index + 1}`,
    occupation: 'Gerente de Vendas',
    user_std_code: `654${100 + index}`,
    qtd_stores: Math.floor(Math.random() * 100) + 1,
    profile_image_file_name: `https://randomuser.me/api/portraits/men/${index}.jpg`
  })),
  status: 200
}

export const mockedLeadersListResponse = {
  serviceSuccess: true,
  hasErrors: false,
  errors: [],
  metadata: {
    pagination: {
      total: 10,
      per_page: 10,
      current_page: 1,
      last_page: 1,
      first_page: 1,
      first_page_url: '/?page=1',
      last_page_url: '/?page=1',
      next_page_url: null,
      previous_page_url: null
    },
    runtime: 35,
    requestId: 'clujqrna8000908jtbk9d45ub'
  },
  data: Array.from({ length: 10 }, (_, index) => {
    const user_std_code = `654${100 + index}`

    return {
      user_name: `Líder ${index + 1}`,
      occupation: 'Líder Regional Financeira',
      network: 'CSS Sudeste',
      user_std_code,
      subsidiary: 'Taubaté',
      qtd_staff: Math.floor(Math.random() * 100) + 1,
      qtd_stores: Math.floor(Math.random() * 100) + 1,
      profile_image_file_name: `https://randomuser.me/api/portraits/men/${index}.jpg`,
      status: getDynamicStatus(index)
    }
  }),
  status: 200
}

const mockedWalletsManagementDetailsResponse = {
  serviceSuccess: true,
  hasErrors: false,
  errors: [],
  metadata: {
    runtime: 27,
    requestId: 'clujqt0b6000a08jt5ct8ffjl'
  },
  data: {
    status: WalletManagementStatus.Closed,
    general_status: WalletManagementGeneralStatus.Closed,
    is_reactivated: false,
    opening_date: '01/09/2022',
    closing_date: '10/09/2022',
    last_processed_date: '10/09/2022',
    updated_at: '10/09/2022',
    specialists: {
      total: 315,
      assigned: 154
    },
    tabs: {
      total: 456,
      assigned: 452
    },
    leader_infos: {}
  },
  status: 200
}

export const getLeadersList = async (params: TParams) => {
  if (USE_MOCK) {
    return Promise.resolve(mockAxiosResponse(mockedLeadersListResponse))
  } else {
    await client.get<ResponseSuccess<TLeadersListItem[]>>(
      `v1/wallets/management/leaders/${params.leader_std_code}`,
      {
        params: {
          per_page: params?.per_page ?? 10,
          page: params?.page ?? 1,
          order_by: params.order_by ?? undefined,
          order_by_direction: params.order_by_direction ?? 'desc',
          ...params
        }
      }
    )
  }
}

export const getStaffList = async (params: TParams) => {
  if (USE_MOCK) {
    return Promise.resolve(mockAxiosResponse(mockedStaffListResponse))
  } else {
    await client.get<ResponseSuccess<TStaffListItem[]>>(
      `v1/wallets/management/leaders/${params.leader_std_code}/staff`,
      {
        params: {
          per_page: params?.per_page ?? 10,
          page: params?.page ?? 1,
          order_by: params.order_by ?? undefined,
          order_by_direction: params.order_by_direction ?? 'desc',
          ...params
        }
      }
    )
  }
}

export const getStoresList = async (params: TParams) => {
  if (USE_MOCK) {
    return Promise.resolve(mockAxiosResponse(mockedStoresListResponse))
  } else {
    await client.get<ResponseSuccess<TStoresListItem[]>>(
      `v1/wallets/management/leaders/${params.leader_std_code}/stores`,
      {
        params: {
          per_page: params?.per_page ?? 10,
          page: params?.page ?? 1,
          filter_by: params?.filter_by ?? undefined,
          ...params
        }
      }
    )
  }
}

export const getWalletsManagementDetails = async (params: TDetailsParams) => {
  if (USE_MOCK) {
    const leaderIndex = Number(String(params.leader_std_code).slice(5))

    const status = getDynamicStatus(leaderIndex)

    const mockResponse = { ...mockedWalletsManagementDetailsResponse }

    mockResponse.data.status = status

    if (params.admin_view) {
      const leaderInfos = {
        user_name: `Líder ${leaderIndex + 1}`,
        occupation: 'Líder Regional Financeira',
        network: 'CSS Sudeste',
        user_std_code: params?.leader_std_code,
        subsidiary: 'Taubaté'
      }

      mockResponse.data.leader_infos = leaderInfos
    }

    return Promise.resolve(mockAxiosResponse(mockResponse)) as any
  } else {
    return await client.get<ResponseSuccess<TWalletsManagementDetails>>(
      `v1/wallets/management/leaders/${params.leader_std_code ?? 0}`,
      {
        params: { admin_view: params.admin_view }
      }
    )
  }
}

export const concludeWalletsManagement = async ({ leader_std_code, stores }: TConcludeParams) =>
  await client.post<ResponseSuccess<TConcludeParams>>(
    `v1/wallets/management/leaders/${leader_std_code}`,
    {
      leader_std_code,
      stores
    }
  )

export const manageWallet = async ({
  staff_std_code,
  stores_tab_number,
  leader_std_code
}: TManageWalletParams) =>
  await client.post<ResponseSuccess<TManageWalletParams>>(
    `v1/wallets/management/leaders/${leader_std_code}/manage`,
    {
      leader_std_code,
      staff_std_code,
      stores_tab_number
    }
  )

export const openWalletsPeriod = async ({
  date_start,
  date_end,
  leader_std_code
}: TOpenWalletsPeriodParams) => {
  if (USE_MOCK) {
    mockedWalletsManagementDetailsResponse.data.status = WalletManagementStatus.Active

    mockedWalletsManagementDetailsResponse.data.general_status = WalletManagementGeneralStatus.Open

    mockedWalletsManagementDetailsResponse.data.opening_date = format(
      new Date(date_start),
      'dd/MM/yyyy'
    )

    mockedWalletsManagementDetailsResponse.data.closing_date = format(
      new Date(date_end),
      'dd/MM/yyyy'
    )

    mockedWalletsManagementDetailsResponse.data.last_processed_date = format(
      new Date(),
      'dd/MM/yyyy'
    ).split('T')[0]

    mockedWalletsManagementDetailsResponse.data.updated_at = new Date().toISOString()

    return Promise.resolve(mockAxiosResponse({})) as any
  } else {
    return await client.post<ResponseSuccess<TOpenWalletsPeriodParams>>(
      `v1/wallets/management/create-period`,
      {
        date_start,
        date_end,
        leader_std_code: leader_std_code ?? undefined
      }
    )
  }
}

export const finishWalletsPeriod = async () => {
  if (USE_MOCK) {
    mockedWalletsManagementDetailsResponse.data.status = WalletManagementStatus.Concluded

    mockedWalletsManagementDetailsResponse.data.general_status =
      WalletManagementGeneralStatus.Closed

    mockedWalletsManagementDetailsResponse.data.closing_date = format(new Date(), 'dd/MM/yyyy')

    mockedWalletsManagementDetailsResponse.data.last_processed_date = format(
      new Date(),
      'dd/MM/yyyy'
    )

    mockedWalletsManagementDetailsResponse.data.updated_at = new Date().toISOString()

    return Promise.resolve(mockAxiosResponse({})) as any
  } else {
    return await client.post<ResponseSuccess<TFinishWalletsPeriodParams>>(
      `v1/wallets/management/finish-period`
    )
  }
}
