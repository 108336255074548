import { useMemo, useState } from 'react'

import {
  TLeadersListItem,
  useFinishWalletsPeriodMutation,
  useGetLeadersListInfinityQuery,
  useGetWalletsManagementDetailsQuery
} from 'integration/resources/walletsManagement'
import { useBreakpointValue, useDisclose } from 'native-base'
import useDebounce from 'src/hooks/useDebounce'
import { useAuthAtomValue } from 'src/store/auth'

import { OrderField, UseLeadersListScreen } from './LeadersListScreen.types'

export const orderFields: OrderField[] = [
  {
    label: 'Nome',
    options: [
      { id: 'asc', name: 'A-Z' },
      { id: 'desc', name: 'Z-A' }
    ]
  },
  {
    label: 'Número de TABs',
    options: [
      { id: 'asc', name: 'Menor para o Maior' },
      { id: 'desc', name: 'Maior para o menor' }
    ]
  }
]

const mergeLeadersInfiniteQuery = (
  data: ReturnType<typeof useGetLeadersListInfinityQuery>['data']
) =>
  data?.pages.reduce<TLeadersListItem[]>(
    // @ts-ignore
    (previousValue, currentValue) => [...previousValue, ...currentValue.data.data],
    []
  ) ?? []

export const useLeadersListScreen: UseLeadersListScreen = ({ navigation, route }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const [search, onSearch] = useState<string>()

  const debouncedSearch = useDebounce(search, 500)

  const [selectedOrderBy, setSelectedOrderBy] = useState<{
    field: string | undefined
    direction: 'asc' | 'desc' | undefined
  }>({
    field: undefined,
    direction: undefined
  })

  const authAtom = useAuthAtomValue()

  const userStdCode = useMemo(() => authAtom?.user?.std_code ?? 0, [authAtom?.user?.std_code])

  const {
    data: leadersListData,
    isLoading: leadersListLoading,
    refetch: leadersListRefetch
  } = useGetLeadersListInfinityQuery({
    leader_std_code: userStdCode,
    order_by: selectedOrderBy?.field === 'Nome' ? 'name' : 'stores_quantity',
    order_by_direction: selectedOrderBy?.direction,
    ...(debouncedSearch?.length && { search: debouncedSearch })
  })

  const listData = useMemo(() => mergeLeadersInfiniteQuery(leadersListData), [leadersListData])

  const { data: walletsManagementDetailsData, isLoading: walletsManagementDetailsIsLoading } =
    useGetWalletsManagementDetailsQuery({ leader_std_code: userStdCode, admin_view: true })

  const detailsData = walletsManagementDetailsData?.data.data

  const { isOpen: orderByIsOpen, onOpen: orderByOnOpen, onClose: orderByOnClose } = useDisclose()

  const { mutate: finishWalletsPeriod, isLoading: finishWalletsPeriodIsLoading } =
    useFinishWalletsPeriodMutation()

  const handleFinishPeriod = () => {
    finishWalletsPeriod({})
  }

  const handleCheckboxChange = (field: string, direction: 'asc' | 'desc') => {
    setSelectedOrderBy({ field, direction })
  }

  const handleApply = () => leadersListRefetch()

  const handleClear = () => {
    setSelectedOrderBy({ field: undefined, direction: undefined })
  }

  const handleGoToStaffList = (userStdCode: string) => {
    navigation.navigate('StaffList', { userStdCode })
  }

  const handleGoToOpenWalletsPeriod = () => {
    navigation.navigate('OpenWalletsPeriod', {})
  }

  return {
    isMobile,
    listData,
    listIsLoading: leadersListLoading,
    detailsData,
    detailsIsLoading: walletsManagementDetailsIsLoading,
    search,
    onSearch,
    handleClear,
    handleApply,
    handleCheckboxChange,
    selectedOrderBy,
    orderByIsOpen,
    orderByOnOpen,
    orderByOnClose,
    handleGoToStaffList,
    handleGoToOpenWalletsPeriod,
    handleFinishPeriod,
    finishWalletsPeriodIsLoading
  }
}
